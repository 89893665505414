@font-face {
  font-family: "KappahlType";
  font-weight: 400;
  src: url(assets/fonts/kappahl-regular.woff2);
}

@font-face {
  font-family: "KappahlType";
  font-weight: 400;
  font-style: italic;
  src: url(assets/fonts/kappahl-regular.woff2);
}

@font-face {
  font-family: "KappahlType";
  font-weight: 450;
  src: url(assets/fonts/kappahl-regular.woff2);
}

@font-face {
  font-family: "KappahlType";
  font-weight: 450;
  font-style: italic;
  src: url(assets/fonts/kappahl-regular.woff2);
}

@font-face {
  font-family: "KappahlType";
  font-weight: 500;
  src: url(assets/fonts/kappahl-regular.woff2);
}
@font-face {
  font-family: "KappahlType";
  font-weight: 500;
  font-style: italic;
  src: url(assets/fonts/kappahl-regular.woff2);
}
@font-face {
  font-family: "KappahlType";
  font-weight: 700;
  src: url(assets/fonts/kappahl-bold.woff2);
}

@font-face {
  font-family: "KappahlType";
  font-weight: 700;
  font-style: italic;
  src: url(assets/fonts/kappahl-bold.woff2);
}
